import React, { lazy, Suspense } from "react";
//React Icons
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { DiHtml5DeviceAccess } from "react-icons/di";
import { TiDeviceDesktop } from "react-icons/ti";
import { VscClippy } from "react-icons/vsc";
import DiscountIcon from "@mui/icons-material/Discount";
import TimelineIcon from "@mui/icons-material/Timeline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CampaignIcon from "@mui/icons-material/Campaign";
import { MdOutlineLocationOn } from "react-icons/md";
import otherCampaignIcon from "./assets/Img/other_campaigns.png";
import otherOrderIcon from "./assets/Img/other_orders.png";

// core component
const Home = lazy(() => import("./views/Home/Home"));
const AdAgency = lazy(() =>
  import("./views/Home/AddUserDialog/AdAgency/Ad-Agency")
);
const AddClusterhead = lazy(() =>
  import("./views/Home/AddUserDialog/AddCluseterHead/Clusterhead")
);
const AddSubAdmin = lazy(() =>
  import("./views/Home/AddUserDialog/AddSub-Admin/SubAdmin")
);
const BuisnesssOwner = lazy(() =>
  import("./views/Home/AddUserDialog/AddBuisnessOwner/BuisnessOwner")
);
const CreateCampaign = lazy(() =>
  import("./views/Home/CreateCampaign/CreateCampaign")
);
// ** Device
const Device = lazy(() => import("./views/Home/Device/Device"));
const DeviceProfile = lazy(() =>
  import("./views/Home/Device/DeviceProfile/AllDeviceProfuile")
);
// Add Bill Board
const AddBillBoard = lazy(() =>
  import("./views/Home/Bill-Board-Add/Add-Bill-Board")
);
const AddBurnerAd = lazy(() =>
  import("./views/Home/Bill-Board-Add-Burner-Ad/AddBurnerAd")
);
// Billboard Details
const BillBoardTab = lazy(() =>
  import("./views/Home/BillboardDetail/BillBoardTab")
);
const BillboardItem = lazy(() =>
  import(
    "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardProfileTab"
  )
);
const MBookingModify = lazy(() =>
  import(
    "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/ModifyBooking.js/MBookingtDetail"
  )
);
// Campaign Management
const Camp_Details = lazy(() =>
  import("./views/Home/CampaigMgt/CampDetail/Camp_Details")
);
///////////////////////////////////////// Content Creator //////////////////////////////////////////////////////////////////////
// Order Management
const OrderDetails = lazy(() =>
  import("./views/Home/Order/OrderDetails/Order-Details")
);
const OrderTab = lazy(() => import("./views/Home/Order/OrderTab"));
// User Management
const UserDashboard = lazy(() => import("./views/Home/UserMgt/UserDashboard"));
const ManageUser = lazy(() =>
  import("./views/Home/UserMgt/Manage-User/ManageUser")
);
const ManageUserProfile = lazy(() =>
  import("./views/Home/UserMgt/Utils/AllUserProfiles")
);
// Map
const Loctaion = lazy(() => import("./views/Home/Map/Map"));
// Notification
const Notification = lazy(() =>
  import("./views/Home/Notification/notification")
);
// Form
// Location
const LocationTab = lazy(() => import("./views/Home/Location/LocationTab"));
// Finance
const Finance = lazy(() => import("./views/Home/Finance/Finance"));
const FinanceTransaction = lazy(() => import("./views/Home/Finance/Finance"));
const FinanceAnalytics = lazy(() => import("./views/Home/Finance/Finance"));
// Coupon
const CouponVoucherTab = lazy(() =>
  import("./views/Home/Coupon&Voucher/Coupon&VoucherTab")
);
const AddVoucher = lazy(() =>
  import(
    "./views/Home/Coupon&Voucher/Coupon&VocherTabPanels/VoucherTabPanel/VoucherTableTab/VoucherActions/AddVoucher"
  )
);
const AddCoupon = lazy(() =>
  import(
    "./views/Home/Coupon&Voucher/Coupon&VocherTabPanels/CouponTabPanel/CouponTableTab/CouponActions/AddCouponDialogue"
  )
);
const WishList = lazy(() => import("./views/Home/WishList/WishList"));
// Profile
const Profile = lazy(() => import("./views/Home/Profile/Profile"));
// Analytics
const Analytics = lazy(() => import("./views/Home/Analytics/Analytics"));
// Campaign
const Campaign_Tab = lazy(() => import("./views/Home/CampaigMgt/Campaign_Tab"));
const ApkManager = lazy(() => import("./views/Home/ApkManager"));
const SelectTimeSlot = lazy(() =>
  import(
    "./views/Home/BillboardDetail/BillBoardSceenDetails/BillboardScreenTab's/timeSlot/index"
  )
);
// Gallery
const GalleryIndex = lazy(() => import("./views/Home/Gallery"));
// Trash
const Trashindex = lazy(() => import("./views/Home/Trash"));
const Tutorial = lazy(() => import("./views/Home/UploadTutorial"));

// routeConfig.js
export const getRoutesBasedOnRole = (
  role,
  routes,
  roleZeroRoutes,
  role2Routes
) => {
  if (role === 2) {
    return [...routes, ...role2Routes];
  } else if (role === 0) {
    return [...routes, ...roleZeroRoutes];
  }
  return routes;
};

const userData = JSON.parse(localStorage.getItem("userData"));
const Role = userData?.user?.userRole;

let routes = [
  // ** Navbar Routes
  {
    link: "/admin/apk-manager",
    Element: <ApkManager />,
  },
  {
    link: "/admin/upload-tutorial",
    Element: <Tutorial />,
  },
  {
    link: "/admin/createcampaign",
    Element: <CreateCampaign />,
    name: "Campaign",
    key: "create-campaign",
  },

  // ** Navbar DropDown Routes
  {
    link: "/admin/notification",
    Element: <Notification />,
  },

  {
    link: "/admin/Profile",
    Element: <Profile />,
  },
  {
    link: "/admin/WishList",
    Element: <WishList />,
  },
  {
    link: "/admin/userdashborad",
    Element: <UserDashboard />,
    name: "User",
  },

  // ** Sidebar Routes
  {
    invisible: false,
    name: "Dashboard",
    link: "/admin/home",
    Element: <Home />,
    icon: <MdOutlineDashboardCustomize />,
  },

  {
    invisible: false,
    key: "view-device",
    link: "/admin/device",
    Element: <Device />,
    icon: <DiHtml5DeviceAccess />,
    name: "Device",
  },
  {
    invisible: false,
    link: "/admin/locationState",
    Element: <LocationTab />,
    icon: <LocationCityIcon />,
    name: "Location",
  },
  {
    invisible: false,
    key: "view-finance-overview",
    link: "/admin/finance",
    Element: <Finance />,
    icon: <CurrencyRupeeIcon />,
    name: "Finance",
  },
  {
    invisible: false,
    key: "view-coupons",
    link: "/admin/CouponVocher",
    Element: <CouponVoucherTab />,
    icon: <DiscountIcon />,
    name: "Coupon",
  },
  {
    invisible: false,
    key: "view-live-billboard",
    link: "/admin/billboardscreens",
    Element: <BillBoardTab />,
    icon: <TiDeviceDesktop />,
    name: "Billboard",
  },

  {
    invisible: false,
    key: "view-live-order",
    link: "/admin/order",
    Element: <OrderTab />,
    icon: <VscClippy />,
    name: Role === 2 ? "My Order" : "Order",
  },
  {
    invisible: false,
    key: "view-user-profile",
    link: "/admin/manage-user",
    icon: <FaRegUserCircle />,
    name: "User",
    Element: <ManageUser />,
  },
  {
    invisible: false,
    key: "view-live-campaign",
    link: "/admin/campaignmgt",
    Element: <Campaign_Tab />,
    icon: <CampaignIcon />,
    name: Role === 2 ? "My Campaign" : "Campaign",
  },
  {
    invisible: false,
    link: "/admin/location",
    Element: <Loctaion />,
    icon: <MdOutlineLocationOn />,
    name: "Map",
  },
  {
    invisible: false,
    link: "/admin/analytics",
    icon: <TimelineIcon />,
    Element: <Analytics />,
    name: "Analytics",
  },

  {
    link: "/admin/deviceprofile/:id",
    Element: <DeviceProfile />,
    name: "Device",
  },

  // ** Other  Finance Routes

  {
    link: "/admin/financeTransaction",
    key: "view-finance-transaction",
    Element: <FinanceTransaction value={4} />,
  },
  {
    link: "/admin/financeAnalytics",
    key: "view-finance-analytics",
    Element: <FinanceAnalytics value={3} />,
  },
  // {
  //   link: "/admin/newadminhome",
  //   Element: <NewAdminHome />,
  // },

  // ** Other  Coupons Routes
  {
    link: "/admin/CouponVocher/AddVoucher",
    key: "create-voucher",
    Element: <AddVoucher />,
    name: "Coupon",
    action: "add",
  },
  {
    link: "/admin/CouponVocher/AddCoupon",
    key: "create-coupons",
    Element: <AddCoupon />,
    name: "Coupon",
    action: "add",
  },

  // ** Other Billboard Routes
  {
    link: "/admin/billboardscreens/:billboard_id",
    Element: <BillboardItem />,
    name: "Billboard",
  },
  {
    link: "/admin/addBurnerAd",
    key: "create-delete-burner-ad",
    Element: <AddBurnerAd />,
  },
  {
    link: "/admin/addbillboard",
    key: "add-billboard",
    Element: <AddBillBoard />,
    name: "billboard",
    action: "Add",
  },

  {
    link: "/admin/selectTimeslot",
    Element: <SelectTimeSlot />,
    name: "Billboard",
  },
  {
    link: "/admin/modifybooking",
    Element: <MBookingModify />,
    name: "Billboard",
  },

  // ** Other Order Routes
  {
    link: "/admin/order/:order_id",
    key: "view-order-detail",
    Element: <OrderDetails />,
    name: "Order",
  },

  {
    link: "/admin/userprofile/:id/:value",
    Element: <ManageUserProfile />,
    name: "User",
    key: "view-user-profile",
  },

  // ** Other User Routes

  //-----------------------SubAdmin
  {
    link: "/admin/addsubadmin/:value",
    Element: <AddSubAdmin />,
    name: "User",
    key: "add-new-user-subadmin",
  },

  //-----------------------Clusterhead
  {
    link: "/admin/addclusterhead/:value",
    Element: <AddClusterhead />,
    key: "add-new-user-clusterhead",
    name: "User",
  },

  //-----------------------Agency
  {
    link: "/admin/AdAgency/:value",
    key: "add-new-user-agency",
    Element: <AdAgency />,
    name: "User",
  },

  //-----------------------BusinessOwner

  {
    name: "User",
    link: "/admin/addbusinessOwner/:value",
    key: "add-new-user-businessowner",
    Element: <BuisnesssOwner />,
  },

  //-----------------------individual

  // ** Other Campaign Routes

  {
    link: "/admin/campaignmgt/:campaign_id",
    Element: <Camp_Details />,
    name: "Campaign",
    key: "view-campaign-detail",
  },
];

const role2Routes = [
  {
    invisible: false,
    link: "/admin/otherOrder",
    key: "view-billboard-order",
    Element: <OrderTab />,
    icon: (
      <img src={otherOrderIcon} alt="Other Order" style={{ width: "20px" }} />
    ),
    name: "Other Orders",
  },
  {
    invisible: false,
    link: "/admin/otherCampaign",
    key: "view-billboard-campaign",
    Element: <Campaign_Tab />,
    icon: (
      <img
        src={otherCampaignIcon}
        alt="Other Campaign"
        style={{ width: "20px" }}
      />
    ),
    name: "Others Campaign",
  },
];
const roleZeroRoutes = [
  // ** Other Gallery Routes
  {
    invisible: false,
    name: "Gallery",
    link: "/admin/gallery",
    Element: <GalleryIndex />,
    icon: <CollectionsOutlinedIcon />,
  },
  // ** Other trash Routes
  {
    invisible: false,
    name: "Trash",
    link: "/admin/trash&restore",
    Element: <Trashindex />,
    icon: <RestoreFromTrashIcon />,
  },
];
// if (Role === 2) {
//   routes = [...routes, ...role2Routes];
// } else if (Role === 0) {
//   routes = [...routes, ...roleZeroRoutes];
// }

const finalRoutes = getRoutesBasedOnRole(
  Role,
  routes,
  roleZeroRoutes,
  role2Routes
);

export default finalRoutes;
